import {LitElement, html} from 'lit-element';
import styles from './splitBlock.css';

export class SplitBlock extends LitElement {
    static get styles() {
        return [styles];
    }

    constructor() {
        super();
    }

    render() {
        return html`<div class="split-block">
            <slot></slot>
        </div>`;
    }
}

customElements.define('split-block', SplitBlock);
