import {css} from 'lit-element';

export default css`:host {
    display: block;
}

.supporter {
    font-size: 1.25rem;
    line-height: 1.75rem;
}

.count {
    position: relative;
    padding: 1rem;
    font-family: "Roboto Slab", sans-serif;
    text-align: right;
    font-weight: bold;
    white-space: nowrap;
}

.name {
    padding: 1rem;
}

.footnote {
    position: absolute;
    top: 0.5rem;
    right: 0;
    font-size: 0.8rem;
    color: #a91c22;
    text-decoration: underline;
}
`;
  