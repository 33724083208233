import {css} from 'lit-element';

export default css`:host {
    display: block;
}

.context-text {
    color: #606060;
    font-size: 1rem;
}
`;
  