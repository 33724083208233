import {LitElement, html} from 'lit-element';
import styles from './newsEntry.css';

export class NewsEntry extends LitElement {
    static get styles() {
        return [styles];
    }

    static get properties() {
        return {
            profileImage: {type: String},
            imageType: {type: String},
            image: {type: String},
            name: {type: String},
            date: {type: String}
        }
    }

    constructor() {
        super();
        this.profileImage = '';
        this.imageType = '';
        this.image = '';
        this.name = '';
        this.date = '';
    }

    render() {
        let newsImage = null;
        if (this.image) {
            newsImage = html`<a href="${this.image}"><img class="news-entry-image" data-image-type="${this.imageType}" src="${this.image}" /></a>`
        }
        return html`<div class="news-entry">
            <div class="news-entry-profileImage">
                <img src="${this.profileImage}" />
            </div>
            <div class="news-entry-body">
                <div class="news-entry-date">${this.date}</div>
                <div class="news-entry-name">${this.name}</div>
                <div class="news-entry-body">
                    <slot></slot>
                    ${newsImage}
                </div>
            </div>
        </div>`;
    }
}

customElements.define('news-entry', NewsEntry);
