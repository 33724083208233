
const DATA_URL = 'https://zeitport.fra1.digitaloceanspaces.com/sherpatime/data/publicData.json';

export class PublicDataProvider {
    constructor() {
        this.publicData = null;
    }

    async loadData() {
        if (!this.publicData) {
            const response = await fetch(DATA_URL);
            this.publicData = await response.json();
            console.log('Public data loaded.');
        }

        return this.publicData;
    }
}

export const publicDataProvider = new PublicDataProvider();
