import {css} from 'lit-element';

export default css`:host {
    display: block;
}

.media-block {
    display: grid;
    grid-template-columns: auto 1fr;
}

.media {

}

.content {
    margin-left: 2rem;
}

@media (max-width:480px) {
    .media-block {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr;
        justify-items: center;
        margin-top: 2rem;
    }

    .media {
        grid-row-start: 1;
    }

    .content {
        grid-row-start: 2;
    }
}
`;
  