import {css} from 'lit-element';

export default css`:host {
    display: block;
    border: solid 1px #e0e0e0;
    padding: 1rem;
    margin-bottom: 2rem;
    border-radius: 4px;
}

.news-entry {
    display: grid;
    grid-template-columns: 3rem 1rem 1fr;
}

.news-entry-profileImage {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    overflow: hidden;
}

.news-entry-profileImage > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.news-entry-body {
    grid-column-start: 3;
}

.news-entry-name {
    font-family: "Roboto Slab", sans-serif;
    font-size: 1rem;
    line-height: 1.5rem;
    font-style: italic;
    color: #606060;
}

.news-entry-date {
    font-family: "Roboto Slab", sans-serif;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: bold;
}

.news-entry-image {
    margin-top: 1rem;
    width: 100%;
}

.news-entry-image2[data-image-type=document] {
}

.news-entry-content {
}
`;
  