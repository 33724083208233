import {LitElement, html} from 'lit-element';
import styles from './statusBar.css';

export class StatusBar extends LitElement {
    static get styles() {
        return [styles];
    }

    static get properties() {
        return {
            progress: {type: Number}
        }
    }

    constructor() {
        super();
        this.progress = 0;
    }

    render() {
        const width = Math.floor(this.progress * 100);

        return html`<div class="status-bar">
            <div class="status-bar-current"><slot name="current"></slot></div>
            <div class="status-bar-target"><slot name="target"></slot></div>
            <div class="progress-bar-background"></div>
            <div class="progress-bar-active" style="width: ${width}%"></div>
        </div>`;
    }
}

customElements.define('status-bar', StatusBar);
