import {css} from 'lit-element';

export default css`:host {
    display: block;
}

.profile-card {
    display: grid;
    grid-template-columns: 128px 1rem 1fr;
}

.profile-card-image {
    width: 128px;
    height: 128px;
    border-radius: 50%;
    overflow: hidden;
}

.profile-card-image > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.profile-card-body {
    grid-column-start: 3;
}

.profile-card-name {
    font-family: "Roboto Slab", sans-serif;
    font-size: 1.5rem;
    line-height: 3rem;
}

.profile-card-mail {

}

.profile-content {
    font-family: "Roboto Slab", sans-serif;
    color: #606060;
    font-style: italic;
}

@media (max-width:480px) {
    .profile-card {
        display: grid;
        grid-template-rows: 128px 1rem 1fr;
        grid-template-columns: 1fr;
        justify-items: center;
    }

    .profile-card-body {
        grid-column-start: 1;
        grid-row-start: 3;
    }

    .profile-card-name,
    .profile-card-mail {
        text-align: center;
    }
}
`;
  