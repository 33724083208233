import {LitElement, html} from 'lit-element';
import styles from './centerPage.css';

export class CenterPage extends LitElement {
    static get styles() {
        return [styles];
    }

    constructor() {
        super();
    }

    render() {
        return html`<div class="center-block"><div><slot></slot></div></div>`;
    }
}

customElements.define('center-page', CenterPage);
