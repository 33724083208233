import {LitElement, html} from 'lit-element';
import styles from './previewBlock.css';

export class PreviewBlock extends LitElement {
    static get styles() {
        return [styles];
    }

    constructor() {
        super();
    }

    render() {
        let block = null;

        if (window.location.href.includes('preview')) {
            block = html`<slot></slot>`;
        }

        return block;
    }
}

customElements.define('preview-block', PreviewBlock);
