import {CenterPage} from './components/centerPage/centerPage.js';
import {CoverBlock} from './components/coverBlock/coverBlock.js';
import {LineDevider} from './components/lineDevider/lineDevider.js';
import {SupportList} from './components/supportList/supportList.js';
import {SplitBlock} from './components/splitBlock/splitBlock.js';
import {IconBox} from './components/iconBox/iconBox.js';
import {MediaBlock} from './components/mediaBlock/mediaBlock.js';
import {TodoList} from './components/todoList/todoList.js';
import {TodoListItem} from './components/todoList/todoListItem.js';
import {StatusBar} from './components/statusBar/statusBar.js';
import {ProfileCard} from './components/profileCard/profileCard.js';
import {BalanceBar} from './components/balanceBar/balanceBar.js';
import {PreviewBlock} from './components/previewBlock/previewBlock.js';
import {NewsEntry} from './components/newsEntry/newsEntry.js';

console.log('Starting app');
