import {css} from 'lit-element';

export default css`:host {
    display: block;
}

.split-block {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

@media (max-width:480px) {
    .split-block {
        display: grid;
        grid-template-columns: 1fr;
    }
}
`;
  