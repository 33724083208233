import {LitElement, html} from 'lit-element';
import styles from './todoListItem.css';

import {mdiCheckboxBlankCircleOutline} from './icons';
import {mdiCheckCircle} from './icons';
import {mdiChevronRightCircle}  from './icons';

const colorMap = {
    open: '#c0c0c0',
    pending: '#1C9EB6',
    done: '#009000'
}

const iconMap = {
    open: mdiCheckboxBlankCircleOutline,
    pending: mdiChevronRightCircle,
    done: mdiCheckCircle
}

export class TodoListItem extends LitElement {
    static get styles() {
        return [styles];
    }

    constructor() {
        super();
        this.status = 'pending';
    }

    static get properties() {
        return {
            status: {type: String}
        }
    }

    render() {
        const fillColor = colorMap[this.status];
        const iconPath = iconMap[this.status];

        const pulsePostfix = this.status === 'pending' ? '-pulse' : '';

        return html`<div class="todo-list-item">
            <div class="icon${pulsePostfix}">
                <svg viewBox="0 0 24 24">
                    <path fill="${fillColor}" d="${iconPath}" />
                </svg>
            </div>
            <div class="content">
                <slot></slot>
            </div>
        </div>`;
    }
}

customElements.define('todo-list-item', TodoListItem);
