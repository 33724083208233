import {css} from 'lit-element';

export default css`:host {
    display: block;
}

.cover-block {
    position: relative;
    display: grid;
    grid-template-columns:
    minmax(0, 1fr)
    minmax(auto, 1280px)
    minmax(0, 1fr);
}

.cover-block-content {
    grid-column: 2;
    position: relative;
    height: 600px;
    max-height: 50vh;
}

.layer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.image > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.title {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title > * {
    grid-row: 2;
}

.dark {
    background-color: rgba(0, 0, 0, 0.2);
}

.line {
    width: 50%;
}
`;
  