import {LitElement, html} from 'lit-element';
import styles from './todoList.css';

export class TodoList extends LitElement {
    static get styles() {
        return [styles];
    }

    constructor() {
        super();
    }

    render() {
        return html`<slot><slot>`;
    }
}

customElements.define('todo-list', TodoList);
