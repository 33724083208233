import {css} from 'lit-element';

export default css`:host {
    display: block;
}

.center-block {
    display: grid;
    grid-template-columns:
    minmax(0, 1fr)
    minmax(auto, 960px)
    minmax(0, 1fr);
}

.center-block > * {
    padding: 3rem 1rem 1rem 1rem;
    grid-column: 2;
}
`;
  