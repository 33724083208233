import {LitElement, html} from 'lit-element';
import styles from './iconBox.css';
import {mdiCreditCardCheck} from './icons';
import {mdiPackageVariant} from './icons';
import {mdiCarLiftedPickup} from './icons';

const icons = {
    mdiCreditCardCheck,
    mdiPackageVariant,
    mdiCarLiftedPickup
};


export class IconBox extends LitElement {
    static get styles() {
        return [styles];
    }

    static get properties() {
        return {
            iconName: {type: String}
        }
    }

    constructor() {
        super();
    }

    render() {
        return html`<div class="icon-box">
            <svg viewBox="0 0 24 24">
                <path d="${icons[this.iconName]}" />
            </svg>
        </div>`;
    }
}

customElements.define('icon-box', IconBox);
