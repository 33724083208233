import {css} from 'lit-element';

export default css`:host {
    display: block;
}

.icon-box {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background-color: #a91c22;
}

.icon-box path {
    fill: white;
}

.icon-box svg {
    margin: 8px;
}

@media (max-width:480px) {
    .icon-box {
        width: 48px;
        height: 48px;
    }
}
`;
  