import {css} from 'lit-element';

export default css`:host {
    display: block;
}

.todo-list-item {
    display: grid;
    grid-template-columns: 32px 1fr;
    margin-bottom: 0.5rem;
}

.icon {

}

.content {
    margin-left: 1rem;
    line-height: 32px;
}

.icon-pulse {
    transform: scale(1);
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(0.90);
    }

    70% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(0.90);
    }
}
`;
  