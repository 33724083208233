import {LitElement, html} from 'lit-element';
import styles from './balanceBar.css';
import {publicDataProvider} from '../../core/publicDataProvider';

export class BalanceBar extends LitElement {
    static get styles() {
        return [styles];
    }

    static get properties() {
        return {
            publicData: {type: Object}
        }
    }

    constructor() {
        super();
        this.publicData = null;
    }

    async connectedCallback() {
        super.connectedCallback();

        this.publicData = await publicDataProvider.loadData();
    }

    render() {
        const progress = this.publicData ? this.publicData.progress : 0;
        let progressPercent = this.publicData ? this.publicData.progressPercentage : 0;

        const goal = this.publicData ? this.publicData.goal : 0;
        const total = this.publicData ? this.publicData.total : 0;

        const barProgress = progress > 1 ? 1 : progress;

        return html`            
        <status-bar progress="${barProgress}">
            <slot slot="current">€ ${total} <span class="context-text">${progressPercent}% erreicht!</span></slot>
            <span slot="target">€ ${goal} 🏆</span>
        </status-bar>
        `;
    }
}

customElements.define('balance-bar', BalanceBar);
