import {css} from 'lit-element';

export default css`:host {
    display: block;
}

.status-bar {
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-template-rows: 3rem 3rem;
    margin: 2rem 0;
}

.status-bar-current {
    grid-column-start: 1;
    font-family: "Roboto Slab", sans-serif;
    font-size: 2rem;
    line-height: 3rem;
    color: #a91c22;
}

.status-bar-target {
    grid-column-start: 3;
    font-family: "Roboto Slab", sans-serif;;
    font-size: 2rem;
    line-height: 3rem;
    color: #a91c22;
}

.progress-bar-background {
    grid-row: 2;
    grid-column-start: 1;
    grid-column-end: 4;
    height: 8px;
    background-color: #e0e0e0;
}

.progress-bar-active {
    grid-row: 2;
    grid-column-start: 1;
    grid-column-end: 4;
    height: 8px;
    background-color: #a91c22;
}
`;
  