import {LitElement, html} from 'lit-element';
import styles from './mediaBlock.css';

export class MediaBlock extends LitElement {
    static get styles() {
        return [styles];
    }

    constructor() {
        super();
    }

    render() {
        return html`<div class="media-block">
            <div class="media">
                <slot name="media"></slot>
            </div>
            <div class="content">
                <slot name="content"></slot>
            </div>
        </div>`;
    }
}

customElements.define('media-block', MediaBlock);
