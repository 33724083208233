import {LitElement, html} from 'lit-element';
import styles from './profileCard.css';

export class ProfileCard extends LitElement {
    static get styles() {
        return [styles];
    }

    static get properties() {
        return {
            profileImage: {type: String},
            name: {type: String},
            mail: {type: String}
        }
    }

    constructor() {
        super();
        this.profileImage = '';
        this.name = '';
        this.mail = '';
    }

    render() {
        return html`<div class="profile-card">
            <div class="profile-card-image">
                <img src="${this.profileImage}" />
            </div>
            <div class="profile-card-body">
                <div class="profile-card-name">${this.name}</div>
                <div class="profile-card-mail">${this.mail}</div>
                <div class="profile-content"><slot></slot></div>
            </div>
        </div>`;
    }
}

customElements.define('profile-card', ProfileCard);
