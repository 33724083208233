import {LitElement, html} from 'lit-element';
import styles from './coverBlock.css';

export class CoverBlock extends LitElement {
    static get styles() {
        return [styles];
    }

    constructor() {
        super();
    }

    render() {
        return html`<div class="cover-block">
            <div class="cover-block-content ">
                <div class="layer image">
                    <img alt="Nurkum Village" src="https://zeitport.fra1.digitaloceanspaces.com/sherpatime/images/village/nurkum-village-1.jpg" />
                </div>
                <div class="layer title">
                        <slot name="title"></slot>
                        <line-devider class="line"></line-devider>
                        <slot name="subtitle"></slot>
                </div>
            </div>
        </div>`;
    }
}

customElements.define('cover-block', CoverBlock);
