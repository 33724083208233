import {LitElement, html} from 'lit-element';
import styles from './supportList.css';
import {publicDataProvider} from '../../core/publicDataProvider.js';

export class SupportList extends LitElement {
    static get styles() {
        return [styles];
    }

    static get properties() {
        return {
            publicData: {type: Object}
        }
    }

    constructor() {
        super();
        this.publicData = null;
    }

    async performUpdate() {
        super.performUpdate();

        this.publicData = await publicDataProvider.loadData();
    }

    render() {
        let rows = [];

        if (this.publicData) {
            const transactions = this.publicData.transactions;

            rows = transactions.map(transaction => {
                const amountText = transaction.amountText || html`${transaction.amount} €`;

                let amountFootnote = null

                if (transaction.amountFootnote) {
                    amountFootnote = html`<div class="footnote">${transaction.amountFootnote}</div>`
                }

                return html`<tr class="supporter">
                    <td class="count">${amountText}${amountFootnote}</td>
                    <td class="name">${transaction.name}</td>
                    <td>${transaction.date}</td>
                </tr>`
            });
        }

        return html`<table class="list">${rows}</table>`;
    }
}

customElements.define('support-list', SupportList);
