import {css} from 'lit-element';

export default css`:host {
    display: block;
    height: 32px;
}

.line {
    height: 16px;
    border-bottom: 2px solid white;
}
`;
  